import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";
import { css, cx } from "linaria";
import { styled } from "linaria/react";
import React, { useState } from "react";
import { Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const StyledSwiperContainer = styled(SectionContainer)`
  background: var(--neutral-100);
  .swiper-scrollbar.swiper-scrollbar-horizontal {
    height: 2px;
    width: 50%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    .swiper-scrollbar-drag {
      background-image: linear-gradient(
        270deg,
        var(--teal-500) 0%,
        var(--blue-500) 100%
      );
    }
    @media (max-width: 820px) {
      width: 90%;
    }
  }
`;

const cardContainer = css`
  display: flex;
  max-width: 100%;
  justify-content: center;
`;

const maskedBoth = cx(
  cardContainer,
  css`
    -webkit-mask: linear-gradient(
      90deg,
      #0000,
      var(--nuetral-900) 10% 80%,
      #0000
    );
    @media (max-width: 1023px) {
      -webkit-mask: none;
    }
  `
);
const maskedStart = cx(
  cardContainer,
  css`
    -webkit-mask: linear-gradient(
      90deg,
      var(--white),
      var(--nuetral-900) 10% 80%,
      var(--nuetral-900)
    );
    @media (max-width: 1023px) {
      -webkit-mask: none;
    }
  `
);
const maskedEnd = cx(
  cardContainer,
  css`
    -webkit-mask: linear-gradient(90deg, #0000, #000 10% 80%, var(--white));
    @media (max-width: 1023px) {
      -webkit-mask: none;
    }
  `
);

const imageStyles = css`
  width: 100% !important;
  aspect-ratio: 1 !important;
  border-radius: 16px !important;
  transition: all 250ms;
  opacity: 0.9;
`;

const cardStyles = css`
  padding-bottom: 20px;
  min-width: 265px;
  a {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-decoration: none;
    -webkit-appearance: none;
    color: var(--nuetral-900);
    cursor: pointer;
    text-decoration: none;
  }
  p {
    max-width: 80%;
  }
  :hover {
    .${imageStyles} {
      opacity: 1;
    }
  }
`;

const pretTitle = css`
  font-weight: 900;
  color: var(--blue-500);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 2;
`;

const title = css`
  font-weight: 900;
  font-size: 20px;
  text-transform: capitalize;
  line-height: 1;
`;

const mainTitle = cx(
  title,
  css`
    font-size: 32px;
  `
);

export default function MoreWaysToLearnSection({ cards, color }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { isMobile } = useIsMobile(1439);

  const updateIndex = swiperInstance => {
    if (swiperInstance === null) return;
    const currentSlide = swiperInstance?.activeIndex;
    setCurrentIndex(currentSlide);
  };

  const renderMask = () => {
    const start = currentIndex === 0;
    const end = isMobile
      ? currentIndex === cards.length - 1
      : currentIndex === 1;
    if (start) {
      return maskedStart;
    }
    if (end) {
      return maskedEnd;
    }
    return maskedBoth;
  };

  return (
    <StyledSwiperContainer style={{ backgroundColor: color }}>
      <SectionInner style={{ flexDirection: "column" }}>
        <h2 className={mainTitle}>More Ways to Learn About Rhombus</h2>
        <div className={isMobile ? renderMask() : cardContainer}>
          <Swiper
            initialSlide={currentIndex}
            onActiveIndexChange={updateIndex}
            slidesPerView="auto"
            spaceBetween={40}
            breakpoints={{
              425: {
                slidesPerView: 1.25,
                scrollbar: {
                  enabled: true,
                  draggable: true,
                  dragSize: 150,
                  snapOnRelease: true,
                },
              },
              625: {
                slidesPerView: 1.5,
                scrollbar: {
                  enabled: true,
                  draggable: true,
                  dragSize: 150,
                  snapOnRelease: true,
                },
              },
              900: {
                slidesPerView: 2.5,
                scrollbar: {
                  enabled: true,
                  draggable: true,
                  dragSize: 250,
                  snapOnRelease: true,
                },
              },
              1230: {
                slidesPerView: 4,
                scrollbar: {
                  enabled: true,
                  draggable: true,
                  dragSize: 125,
                  snapOnRelease: true,
                },
              },
            }}
            scrollbar={{
              enabled: true,
              draggable: true,
              snapOnRelease: true,
            }}
            modules={[Scrollbar]}
          >
            {cards.map(card => (
              <SwiperSlide className={cardStyles}>
                <Link to={card.link}>
                  <GatsbyImage
                    image={getImage(card.image)}
                    alt={card.title}
                    className={imageStyles}
                  />
                  <h3 className={pretTitle}>{card.preTitle}</h3>
                  <h2 className={title}>{card.title}</h2>
                  <p>{card.blurb}</p>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </SectionInner>
    </StyledSwiperContainer>
  );
}
