import HeroLayout2 from "components/common/hero/HeroLayout2";
import LearnSection from "components/common/LearnSection";
import MoreWaysToLearnSection from "components/common/MoreWaysToLearnSection";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function personalizedTraining() {
  const renderContent = data => {
    const headerSection = {
      preTitle: "PERSONALIZED training",
      title: "Unlock the Full Potential of Your Security Platform",
      formId: "c4703f04-e7cc-4571-a88c-8e0d98142905",
      image: data.headerImage,
      mobileImage: data.headerImage,
    };

    const learnLines = [
      "Personalized Guidance: Training tailored to your specific needs and goals.",
      "Q&A Time: Ask all your burning questions in real time.",
      "Pro Tips: Discover key ways to use Rhombus quickly and effectively.",
    ];

    const speakers = [
      {
        name: "Janie Lynne Lovejoy",
        title: "Customer Success Manager ",
        image: data.speaker1,
      },
      {
        name: "Nicky Feinstein",
        title: "Enterprise Customer Success Manager",
        image: data.speaker3,
      },
    ];

    const cards = [
      {
        image: data.image1,
        preTitle: "Discover",
        title: "The Rhombus Blog",
        blurb: "See how Rhombus can improve your physical security experiences",
        link: "/blog/",
      },
      {
        image: data.image2,
        preTitle: "Connect",
        title: "Events & Webinars",
        blurb:
          "Connect with the Rhombus team through our live events & webinars.",
        link: "/events/",
      },
      {
        image: data.image3,
        preTitle: "Explore",
        title: "Support Hub",
        blurb: "Find FAQs and step-by-step instructions for solutions.",
        link: "https://support.rhombussystems.com/hc/en-us",
      },
      {
        image: data.image4,
        preTitle: "watch",
        title: "Instant Demo",
        blurb: "Watch a five-minute demo to learn more about the platform",
        link: "/lp/instant-demo/",
      },
    ];

    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>Personalized Training Session — Rhombus</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <HeroLayout2
          data={headerSection}
          formTitle="Request a Training Session"
        />
        <LearnSection
          description="At Rhombus, we are committed to helping you get the most out of your new security platform. That's why we're excited to offer you the opportunity to book a one-on-one training/onboarding session with one of our experts."
          learnLines={learnLines}
          speakers={speakers}
          image={data.about}
        />
        <MoreWaysToLearnSection cards={cards} color="var(--nuetral-100)" />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/landing-pages/img/training-hero-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      speaker1: file(
        relativePath: { eq: "components/common/img/headshots/janie.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      speaker3: file(
        relativePath: { eq: "components/common/img/headshots/nicky.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      about: file(
        relativePath: { eq: "components/careers/img/benefits-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      image1: file(
        relativePath: { eq: "components/thank-you/img/blog-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      image2: file(
        relativePath: {
          eq: "components/common/img/more-ways-to-learn/events.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      image3: file(
        relativePath: { eq: "components/thank-you/img/pricing-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      image4: file(
        relativePath: { eq: "components/thank-you/img/instant-demo-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
