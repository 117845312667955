import React from "react";
import { css } from "linaria";
import SlideInContainer from "components/common/SlideInContainer";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  FancyTitleMed,
  MainParagraph,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";

const containerClass = css`
  display: flex;
  justify-content: space-between;
  gap: 55px;
  padding: 75px 0px;
  width: var(--max-width);
  margin: 0 auto;

  @media (max-width: 1480px) {
    width: 100%;
  }

  @media (max-width: 1175px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 600px) {
    padding: 44px 0px 0px;
  }
`;

const leftContainer = css`
  width: 40%;
  display: flex;
  justify-content: flex-start;
  @media (max-width: 1480px) {
    padding-left: 2.5rem;
  }
`;

const learnClass = css`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

const learnContainer = css`
  padding: 25px 0px 75px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  max-width: 693px;
`;

const learnLineClass = css`
  display: flex;
  .gatsby-image-wrapper {
    overflow: visible;
  }
`;
const bulletClass = css`
  width: 25px;
  height: 25px;
  margin: 5px 25px 0px 0px;
  aspect-ratio: 1;
  overflow: visible;
`;

const speakerClass = css`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2.5rem;
  margin-top: 2.5rem;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const speakerCard = css`
  display: flex;
  gap: 22px;

  h4,
  p {
    font-size: 14px;
    line-height: 28px;
  }
  h4 {
    font-weight: 900;
  }
`;

const speakerImgClass = css`
  width: 95px;
`;

const rightContainer = css`
  width: 60%;
  max-width: 693px;
  .about {
    margin-bottom: 2.5rem;
  }

  @media (max-width: 1480px) {
    padding-right: 2.5rem;
  }

  @media (max-width: 1175px) {
    width: 100%;
    justify-content: center;
    padding: 0px 2.5rem;
  }

  @media (max-width: 1110px) {
    padding: 0px 20px;
  }

  @media (max-width: 400px) {
    padding: 0 10px;
  }

  h1 {
    background-image: linear-gradient(
      to left,
      var(--teal-500),
      var(--blue-500)
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    margin-bottom: 31px;
  }
`;

const imageClass = css`
  max-width: 570px;
  border-radius: 1.25rem !important;
  overflow: hidden !important;
  @media (max-width: 1175px) {
    display: none !important; //must be used to override gatsby image styling
  }
`;

export default function LearnSection({
  title = "What You’ll Learn",
  description,
  image,
  learnLines,
  speakers,
  color = "var(--white)",
}) {
  const imageRef = getImage(image);

  return (
    <section style={{ backgroundColor: color }}>
      <div className={containerClass}>
        <div className={leftContainer}>
          <GatsbyImage
            image={imageRef}
            className={imageClass}
            objectPosition="top"
            objectFit="contain"
            alt={title}
          />
        </div>
        <div className={rightContainer}>
          <SlideInContainer>
            <FancyTitleMed style={{ textAlign: "left" }} className="about">
              {title}
            </FancyTitleMed>
          </SlideInContainer>
          <MainParagraph className="about">{description}</MainParagraph>
          <div className={learnClass}>
            <div className={learnContainer}>
              {learnLines.map((line, i) => {
                return (
                  <SlideInContainer key={i}>
                    <div className={learnLineClass}>
                      <StaticImage
                        src="../../images/validation.png"
                        alt="checked"
                        className={bulletClass}
                        placeholder="blurred"
                        objectFit="contain"
                      />
                      <p>{line}</p>
                    </div>
                  </SlideInContainer>
                );
              })}
            </div>
          </div>
          <>
            <TitleSmall>Meet Your Experts</TitleSmall>
            <div className={speakerClass}>
              {speakers.map(speaker => {
                const imageRef = getImage(speaker.image);
                return (
                  <div key={speaker.name} className={speakerCard}>
                    <GatsbyImage
                      image={imageRef}
                      alt={speaker.name}
                      objectFit="contain"
                      className={speakerImgClass}
                    />
                    <div>
                      <h4>{speaker.name}</h4>
                      <p>{speaker.title}</p>
                      <p>{speaker.company}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        </div>
      </div>
    </section>
  );
}
